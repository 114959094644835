@import '../common.scss';

.ParticipantDuplication {
  @include flex-column;
  padding: 35px;
}

.participantDuplication__table {
  @include flex-column;
}

.participantDuplication__label {
  @include label;
}

.participantDuplication__table {
  margin-top: 23px;
}

.participantDuplication__table__header {
  padding: 12px 25px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  background-color: #f2f3f7;
}

.participantDuplication__table__header__label--name,
.participantDuplication__table__header__label--address,
.participantDuplication__table__header__label--phone,
.participantDuplication__table__header__label--action {
  @include value;
}

.participantDuplication__table__body__row__value--name,
.participantDuplication__table__body__row__value--address,
.participantDuplication__table__body__row__value--phone {
  font-weight: 500;
  font-size: 14px;
}

.participantDuplication__table__body__row {
  display: flex;
  justify-content: space-between;
  padding: 26px 17px;
}

.participantDuplication__item {
  width: 18%;
  font-weight: 500;
  font-size: 14px;
}
.participantDuplication__item2 {
  width: 14%;
  font-weight: 500;
  font-size: 14px;
}
.participantDuplication__table__header__label--name {
  width: 200px;
}
.participantDuplication__table__header__label--address {
  width: 100px;
  // margin-left: -45.5px;
}

.participantDuplication__table__header__label--phone {
  width: 91px;
  // margin-right: -142.5px;
}

.participantDuplication__table__header__label--action {
  width: 34px;
}

.participantDuplication__table__body__row__value--name {
  width: 200px;
}
.participantDuplication__table__body__row__value--address {
  width: 100px;
}

.participantDuplication__table__body__row__value--phone {
  width: 91px;
  // margin-left: -50px;
}

.participantDuplication__table__body__row__value--action {
  width: 34px;
  // display: flex;
  // align-self: flex-start;

  display: flex;
  justify-self: flex-end;
  justify-content: flex-end;
}
