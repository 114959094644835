@import '../../../../../theme/variables.ems';

.voterRegisterFollowUp__form {
  display: flex;
  flex-flow: column;
  align-items: center;
  text-align: center;
  padding: 62px 193px 67px 193px;

  @media only screen and (max-width: 768px) {
    padding: 10px;
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.voterRegisterFollowUp__form__options {
  margin-top: 29px;
}
.confirmCode__resendLink {
  margin-left: 5px;
  color: $emsPrimaryColor;
  &:hover {
    opacity: 0.8;
    cursor: pointer;
    text-decoration: underline;
  }
}
