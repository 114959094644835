@import '../../../../../styles/variables.scss';

.importAddrList__tableContanier {
  width: 100%;
  background-color: #fff;
  border-radius: 12px;
  height: 100%;
  margin-top: 25px;
  padding: 21px 27px;
  //   margin-right: 95px;
}

.importAddrList__statusLabel,
.importAddrList__channelLabel {
  font-weight: 600;
  font-size: 12px;
  width: fit-content;
  height: 26px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  padding-left: 11.5px;
  padding-right: 11.5px;
  justify-content: center;
  font-weight: 700;
}

.importAddrList__channelLabel--online {
  color: #3699ff;
  background-color: #f5f8fa;
}

.importAddrList__channelLabel--inPerson {
  color: #f1416c;
  background-color: #fff5f8;
}

.importAddrList__statusLabel--na {
  color: #6c7289;
  background-color: #f2f3f7;
}

.importAddrList__statusLabel--warning {
  color: #fff;
  background-color: $warningColor;
}

.importAddrList__statusLabel--success {
  color: $successColor;
  background-color: #e8fff3;
}

.importAddrList__statusLabel--failed {
  color: #f1416c;
  background-color: #fff5f8;
}

.importAddrList__statusLabel--notused {
  color: #f1bc00;
  background-color: #fef2c7;
}

.importAddrList__updateBtn {
  height: 35px;
  width: 122px;

  font-weight: 600;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  color: #fff;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}

.importAddrList__updateBtn--active {
  background-color: #3699ff;
}

.importAddrList__updateBtn--disabled {
  background-color: #eef2f5;

  &:hover {
    opacity: 1;
    cursor: auto;
  }
}

.importAddrList__tableHeader__columnLabel--1,
.importAddrList__tableHeader__columnLabel--2,
.importAddrList__tableHeader__columnLabel--3,
.importAddrList__tableHeader__columnLabel--4,
.importAddrList__tableHeader__columnLabel--5 {
  font-weight: 600;
  font-size: 14px;
  color: #3f4254;
}

.importAddrList__tableBody__rowItem--1,
.importAddrList__tableBody__rowItem--2,
.importAddrList__tableBody__rowItem--4 {
  font-size: 14px;
  font-weight: 500;
  color: #3f4254;
}

// HEADER WIDTHS
.importAddrList__tableHeader__columnLabel--1 {
  width: 15%;
}
.importAddrList__tableHeader__columnLabel--2 {
  width: 26%;
}
.importAddrList__tableHeader__columnLabel--3 {
  width: 10%;
}
.importAddrList__tableHeader__columnLabel--4 {
  width: 13%;
}
.importAddrList__tableHeader__columnLabel--5 {
  width: 13%;
}

// ROW WIDTHS
.importAddrList__tableBody__rowItem--1 {
  width: 15%;
}
.importAddrList__tableBody__rowItem--2 {
  width: 21%;
}
.importAddrList__tableBody__rowItem--3 {
  width: 15%;
  display: flex;
  justify-content: center;
}
.importAddrList__tableBody__rowItem--4 {
  width: 15%;
}

.importAddrList__tableBody__rowItem--5 {
}

.importAddrList__tableTitle {
  font-size: 20px;
  font-weight: 500;
  color: #181c32;
  margin-bottom: 18px;
  margin-left: 25px;
}

.importAddrList__banner {
  height: 81px;
  border: 1px dashed $primaryColor;
  border-radius: 6px;
  background-color: $primaryLightColor;
  margin-top: 28px;
  margin-bottom: 25px;

  display: flex;
  flex-flow: row;
  justify-content: space-between;
  align-items: center;

  padding-top: 20px;
  padding-left: 15px;
  padding-bottom: 24px;
  padding-right: 20px;
}

.importAddrList__banner__imgWrap {
  display: flex;
  flex-flow: row;
}

.importAddrList__bannerImg {
  width: 40px;
  height: 40px;
}

.importAddrList__bannerTxtWrap {
  margin-left: 12.57px;
  display: flex;
  flex-flow: column;
}

.importAddrList__bannerTxt__instruction {
  font-weight: 700;
  font-size: 16px;
  color: #3f4254;
}

.importAddrList__bannerTxt__date {
  font-weight: 400;
  font-size: 16px;
  color: $primaryColor;
}

.importAddrList__bannerBtn {
  height: 35px;
  width: fit-content;
  padding-left: 15px;
  padding-right: 15px;

  font-weight: 600;
  font-size: 14px;
  border: none;
  border-radius: 6px;
  color: #fff;
  background-color: $primaryColor;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
}
