.SuperAdmin__ParticipantDetails {
  padding-left: 265px;
  padding-top: 85px;
  padding-right: 50px;
  padding-bottom: 100px;

  display: flex;
  flex-flow: column;

  @media only screen and (max-width: 1370px) {
    margin-top: 60px;
  }
}

.participantDetails__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 830px;
}

.participantDetails__header__surveyBtn {
  border: 2px solid #000;
  display: flex;
  align-items: center;
  width: 219px;
  justify-content: center;
  border-radius: 6px;
  height: 46px;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  margin-bottom: 19px;
}

.participantDetails__header__surveyBtn__label {
  font-weight: 600;
  font-size: 15px;
  margin-left: 10px;
}

.participantDetails__body__header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 30px 37px 35px 37px;
}

.participantDetails__body {
  display: flex;
  flex-flow: column;
  background-color: #fff;
  height: fit-content;
  width: 830px;
  border-radius: 12px;
  // position: fixed;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);
}

.participantDetails__body__header__voterName {
  font-weight: 500;
  font-size: 22px;
}

.participantDetails__body__header__voterID {
  font-weight: 500;
  font-size: 18px;
  margin-left: 30px;
}

.participantDetails__body__header__nameContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.participantDetails__body__header__statusWrap {
  display: flex;
  margin-left: 17px;
}

.participantDetails__body__header__statusWrap > :not(:last-child) {
  margin-right: 10px;
}

.participantDetails__username {
  font-weight: 500;
  font-size: 18px;
  padding: 10px;
  margin-left: 30px;
  margin-top: 15px;
  margin-bottom: 15px;;

}