@import '../../../../theme/variables.ems';

.VoterLandingPage {
  width: 100% !important;
  height: 100% !important;

  display: flex;
  flex-flow: column;
  justify-content: center;
  background-color: rgb(153, 153, 135);
}
// Layout
.voterLandingPg__body {
  // position: absolute; //wtf
  width: 100%;
  // margin-top: 89px;
}
.voterLandingPg__body__howToCard__btnWrap {
  @media only screen and (min-width: 768px) {
    &.login-mobile {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    &.login-desktop {
      display: none;
    }
  }
}

.voterLandingPg__bodyWrap {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.voterLandingPg__bodyContentWrap {
  max-width: 770px;
  // margin-top: 400px;
}
// -- end

.voterLandingPg__body__title {
  display: flex;
  flex-flow: column;
}

.voterLandingPg__body__title__heading {
  font-size: 2rem;
  font-weight: bold;
  color: white;
  margin-top: 5rem;
}

.voterLandingPg__body__title__subtitle {
  margin-top: -10px;
  font-size: 30px;
  color: #fff;
  font-weight: 500;
}

// how to card
.voterLandingPg__body__howToCard {
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  background-color: #fff;
  margin-top: 40px;

  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(36, 36, 36, 0.1);

  max-height: fit-content;
  padding: 39px;
}

.voterLandingPg__body__howToCard__content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.voterLandingPg__body__howToCard__content__txt {
  display: flex;
}

.voterLandingPg__body__howToCard__content__txtWrap {
  display: flex;
  flex-flow: column;
  margin-right: 44px;
}

.voterLandingPg__body__howToCard__content__txt__title {
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 25px;
  color: $emsPrimaryColor;
}

.voterLandingPg__body__howToCard__content__txt__desc {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 41px;
}

.voterLandingPg__body__howToCard__content__icon {
  width: 122px;
  margin-bottom: 20px;
}
// -- end

.voterLandingPg__body__needHelpCard {
  display: flex;
  height: fit-content;
  border: 2px solid $emsPrimaryColor;
  border-radius: 12px;
  padding: 20px 23px;
  margin-top: 41px;
}

.voterLandingPg__body__needHelpCard__title {
  font-weight: 600;
  font-size: 24px;
  margin-right: 25px;
}

.voterLandingPg__body__needHelpCard__subTxt {
  margin-right: 44px;
  display: flex;
  flex-flow: column;
}

.voterLandingPg__body__needHelpCard__subTxt__desc {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
}

.voterLandingPg__body__needHelpCard__subTxt__number {
  font-weight: 600;
  font-size: 16px;
  color: $emsPrimaryColor;
}

.voterLandingPg__body__needHelpCard__subTxt__tollLabel {
  font-weight: 600;
  font-size: 16px;
  margin-left: 5px;
}
.bannerBackground {
  background-image: url('../../../../../public/media/logosbanners/hamiltonbanner.png');
  background-repeat: repeat-x;
  background-color: #f2f3f7;
  width: 100%;
}

// mobile
.VoterLandPgMobile {
  background-image: url('../../../../../public/media/logosbanners/hamiltonbanner.png');
  background-size: cover;
  height: 100vh;
  width: 100%;
  padding-left: 27px;

  display: flex;
  align-items: flex-end;
  padding-bottom: 45px;
}

.voterLandPgMobile__content {
  width: 100%;
}

.voterLandPgMobile__content__txt {
  display: flex;
  flex-flow: column;
  line-height: 55px;
  margin-top: 45px;
  margin-bottom: 35px;
}

.voterLandPgMobile__content__txt__title {
  font-size: 55px;
  font-weight: 700;
  color: #fff;
}

.voterLandPgMobile__content__txt__date {
  // margin-top: -10px;
  font-size: 30px;
  color: #fff;
  font-weight: 500;
}

.voterLandingPg__checkStatusLink {
  font-size: 16px;
  color: $emsPrimaryColor;
  margin-top: 35px;

  &:hover {
    cursor: pointer;
    opacity: 0.7;
    text-decoration: underline;
  }
}

.VoterLandPgMobile__content__logo {
  width: 210px;
}
