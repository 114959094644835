.eligibleElectorsList__rowItem--id,
.eligibleElectorsList__columnLabel--id {
  width: 10%;
  max-width: 10%;
  word-wrap: break-word;
}

.eligibleElectorsList__rowItem--name,
.eligibleElectorsList__columnLabel--name {
  width: 10%;
  max-width: 10%;
  word-wrap: break-word;
}

.eligibleElectorsList__rowItem--district,
.eligibleElectorsList__columnLabel--district {
  width: 8%;
  max-width: 8%;
  word-wrap: break-word;
}

.eligibleElectorsList__rowItem--addr,
.eligibleElectorsList__columnLabel--addr {
  width: 15%;
  max-width: 15%;
  padding-right: 1%;
  word-wrap: break-word;
}

.eligibleElectorsList__rowItem--channel,
.eligibleElectorsList__columnLabel--channel {
  width: 14%;
  max-width: 14%;
  word-wrap: break-word;
}

.eligibleElectorsList__rowItem--dob,
.eligibleElectorsList__columnLabel--dob {
  width: 10%;
  max-width: 10%;
  word-wrap: break-word;
}

.eligibleElectorsList__rowItem--voted,
.eligibleElectorsList__columnLabel--voted {
  width: 10%;
  max-width: 10%;
  word-wrap: break-word;
}

.eligibleElectorsList__tableHeader--french {
  padding: 25px;
}
