.followUpQuestionModal__thankYou,
.followUpQuestionModal__question2,
.followUpQuestionModal__question3,
.followUpQuestionModal__question4 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  text-align: center;
  padding: 84px 79px 84px 79px;

  @media only screen and (max-width: 768px) {
    padding: 30px 10px;
  }
}

.followUpQuestionModal__question2 {
  padding: 30px 40px 169px 40px;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100% !important;
}

.followUpQuestionModal__question3 {
  padding: 30px 40px 47px 40px;

  align-items: flex-start;
  justify-content: flex-start;
  width: 100% !important;
  text-align: left;
}

.followUpQuestionModal__question4 {
  padding: 59px 40px 79px 40px;
  text-align: left;
}
.followUpQuestionModal__question5 {
  text-align: center;
  padding: 86px 40px 195px 40px;
  @media only screen and (max-width: 768px) {
    padding: 90px 40px;
  }
}

.followUpQuestionModal__inputTxtWrap {
  display: flex;
  flex-flow: column;
}
.followUpQuestionModal__thankYou__icon {
  margin-bottom: 28px;
}

.followUpQuestionModal__title {
  font-weight: 500;
  font-size: 29px;
  margin-bottom: 10px;
}

.followUpQuestionModal__bodyTxt {
  font-weight: 500;
  font-size: 16px;
}
