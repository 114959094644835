@import '../../../../../../styles/variables.scss';
.VotingReceiptM__receipt {
  padding: 58px 53px 66px 53px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  width: 100% !important;

  @media only screen and (max-width: 768px) {
    padding: 20px;
  }

  @media only screen and (max-height: 478px) {
    padding: 20px;
  }
}

.votingReceiptM__receipt__txt {
  font-weight: 500;
  font-size: 18px;
  color: #3f4254;
  margin-bottom: 51px;
  text-align: center;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.VotingReceiptM__valid {
  padding: 20px 25px 36px 25px;
}

.votingReceiptM__valid__code {
  display: flex;
  flex-flow: column;
}

.votingReceiptM__valid__code__content {
  display: flex;
  flex-flow: row;
}

.votingReceiptM__valid__status__content {
  display: flex;
  flex-flow: column;
  margin-top: 31px;
}

.votingReceiptM__valid__code__content__codeVal {
  font-size: 18px;
  font-weight: 600;
  color: #181c32;
}

.votingReceiptM__valid__code__content__outcome {
  display: flex;
  flex-flow: row;
  align-items: center;
  margin-left: 31px;
}

.votingReceiptM__valid__status__content__outcome {
  display: flex;
  flex-flow: row;
  align-items: flex-start;

  margin-top: 22px;
}
.votingReceiptM__valid__code__content__outcome__txt {
  color: $successColor;
}
.votingReceiptM__valid__code__content__outcome__txt,
.votingReceiptM__invalid__code__content__outcome__txt {
  font-size: 16px;
  font-weight: 600;
  margin-left: 4px;
}

.votingReceiptM__invalid__code__content__outcome__txt {
  color: #f1416c;
  margin-right: 100px;
}

.votingReceiptM__valid__status__content__outcome__txt {
  font-size: 18px;
  font-weight: 600;
  color: #181c32;
  margin-left: 25px;

  margin-right: 200px;

  @media only screen and (max-width: 768px) {
    margin-right: 0px;
  }
}

.votingReceiptM__valid__code__label,
.votingReceiptM__valid__status__content__label {
  font-size: 16px;
  font-weight: 400;
  color: #3f4254;
}

.votingReceiptM__input {
  text-align: left;
}

.votingReceiptM__input--verifyCode {
  // margin-top: 40px;
}

.votingReceiptM__valid__status__content__outcomeTxtContainer {
  display: flex;
  flex-flow: column;
  margin-left: 25px;
}

.votingReceiptM__valid__status__content__outcome__desc {
  font-size: 18px;
  margin-top: 23px;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
}

.votingReceiptM__valid__status__date {
  display: flex;
  flex-flow: column;
  margin-top: 50px;
}

.votingReceiptM__valid__status__date__label,
.votingReceiptM__valid__status__date__value {
  font-size: 16px;
}

.votingReceiptM__valid__status__date__value {
  color: #08b055;
  font-weight: 600;
}
