.voterElectorList__header {
  margin-left: 25px;
  display: flex;
  flex-flow: row;
  justify-content: space-between;
}

.voterElectorList__header__label {
  font-weight: 700;
  font-size: 20px;
  color: #7c3242;
}

.voterElectorList__header__inputsWrap {
  display: flex;
  flex-flow: row;
}

//ROWS
.electorListTable__item--1 {
  width: 9%;
  padding-right: 1%;
}
.electorListTable__item--10 {
  width: 9%;
}
.electorListTable__item--2 {
  width: 9%;
  max-width: 9%;
  min-width: 9%;
  padding-right: 1%;
}
.electorListTable__item--3 {
  width: 14%;
  max-width: 14%;
  min-width: 14%;
  overflow-wrap: break-word;
  padding-right: 2%;
}

.electorListTable__item--4,
.electorListTable__item--5,
.electorListTable__item--6,
.electorListTable__item--7,
.electorListTable__item--8 {
  width: calc(50% / 5.5);
  overflow-wrap: break-word;
  padding-right: 1%;
}

.electorListTable__item--w5 {
  width: 5%;
}

.electorListTable__item--9 {
  display: flex;
  width: 5%;
  &.row-item-ellipsis {
    padding-right: 5px;
  }

  &:hover {
    cursor: pointer;
  }
}

// ROW STYLING
.electorListTable__rowItem--1,
.electorListTable__rowItem--2,
.electorListTable__rowItem--3,
.electorListTable__rowItem--4,
.electorListTable__rowItem--5,
.electorListTable__rowItem--6 {
  font-size: 14px;
  font-weight: 500;
  color: #3f4254;
}

.electorListTable__rowItem--4 {
  height: fit-content;
}

// header
.voterInfoModal__header__electorId {
  font-size: 22px;
  font-weight: 500;
  margin-right: 34px;
  color: #6c7289;
}

.voterInfoModal__header__leftWrap,
.voterInfoModal__header__rightWrap {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.voterInfoModal__header__title {
  font-size: 22px;
  font-weight: 500;
  margin-right: 20px;
}
